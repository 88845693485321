@font-face {
  font-family: "RobotoCondensed";
  src: url("Fonts/Roboto_Condensed/RobotoCondensed-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "HeadingNow";
  src: url("Fonts/heading_now/HeadingNowTrial-64Regular.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "HeadingNow94";
  src: url("Fonts/heading_now91/Heading-Now-94-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "HeadingNow91";
  src: url("Fonts/heading_now91/Heading-Now-91-Thin.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Josefin";
  src: local("Coluna"), url('./Fonts/Josefin/JosefinSans-VariableFont_wght.ttf') format("opentype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
/*
Heading Now 91-9
Karumbi
*/
.App {
  /* overflow: scroll; */
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  /* font: inherit; */
  vertical-align: baseline;
  /* font-family: droid-sans, sans-serif; */
  font-family: Josefin, sans-serif;
  font-weight: 400;
  font-style: normal;
}
td,th{
  padding: 5px;
}

p {
  line-height: 20px;
  margin: 0 0 24px;
}

h2 {
  font-family: HeadingNow94;
  margin-bottom: 15px;
  font-size: 32px;
}
h3 {
  font-family: HeadingNow94;
  margin-bottom: 10px;
  font-size: 21px;
  line-height: 40px;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

.page {
  padding: 60px 20px 5px 20px;
  height: fit-content;
  min-height: 100vh;
  background-color:#ffffff;
}
.container {
  padding: 0 15px;
}
.btn-blue {
  padding: 10px;
  cursor: pointer;
  background-color: #0077c0;
  color: white;
  text-decoration: none;
}
/* Header */
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 65px;
  padding: 15px;
  background-color: black;
  color: white;
}
.header .header--title {
  display: flex;
  align-items: center;
}
.header .header--title a {
  text-decoration: none;
  color: white;
}
.header .logo {
  text-decoration: none;
  font-size: 30px;
  color: inherit;
  margin-right: 20px;
}
.header .social a {
  color: white;
}

/* Menu */
.main-menu {
  width: 50%;
  /* box-shadow: 0 0 10px 0px white; */
  border: 1px solid #e1ab01;
}
.main-menu .menu-item {
  padding: 15px;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
}
.main-menu a {
  color: white;
  text-decoration: none;
  display: block;
  width: 100%;
}
.main-menu.button-menu{
  border: none;
}
.main-menu.button-menu .menu-item{
  background-color: #e1ab01;
  margin: 0 auto;
  margin-bottom: 10px;
  width: 75%;
  text-align: center;
}
.main-menu.button-menu .menu-item a,
.main-menu.button-menu .menu-item div{
  color: #000;
  font-weight: 700;
}
.btn-yellow{
  background-color: #e1ab01;
  margin: 0 auto;
  padding: 10px;
  margin-bottom: 10px;
  text-align: center;
  color: #000;
  font-weight: 700;
}
.menu-page{
  background: linear-gradient( rgba(0, 0, 0, .7), rgba(0, 0, 0, 1) ), url('./images/Background/skyline.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/* MODAL */
.modal-container{
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  z-index: 999;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,0.8);
}
.modal{
  width: 90%;
  height: 90%;
  background-color: black;
  border: 1px solid #e1ab01;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.modal--content{
  overflow-x: hidden;
  overflow-y: auto;
  width:100%;
  height:70%;
  padding: 0 15px;
}
.modal--header h2{
  margin: 0;
  font-size: 26px;
}
.modal .btn-yellow{
  width: 80%;
}
/* CONFIRMATION MODAL */

.confirmation-modal-container{
  color:#ffffff;
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  z-index: 999;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,0.8);
}
.confirmation-modal{
  width: 90%;
  height: 50%;
  padding: 15px;
  background-color: black;
  border: 1px solid #e1ab01;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.confirmation-modal--content{
  overflow-x: hidden;
  overflow-y: auto;
  width:100%;
  height:70%;
  padding: 0 15px;
}
.confirmation-modal--header h2{
  margin: 0;
  font-size: 26px;
  text-align: center;
}
.confirmation-modal .btn-yellow{
  width: 80%;
}

.slider--logo-container{
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}
.slider--logo{
  top: 50%;
  position: absolute;
  opacity: .5;
}
.small{
  font-size: 11px;
}

/* TABS */
.tabs {
  position: relative;
  min-height: 100vh;
  clear: both;
  margin: 25px 0;
}

.tab{
  float: left;
}

.tab label{
  background: black;
  padding: 10px;
  border: 1px solid #e1ab01;
  margin-left: 1px;
  position: relative;
  left: 1px;
}

.tab [type=radio]{
  display: none;
}

.content{
  position: absolute;
  top: 28px;
  left: 0;
  background: black;
  right: 0;
  bottom: 0;
  padding: 20px;
  border: 1px solid #e1ab01;
  overflow-y: auto;
}

[type=radio]:checked ~ label{
  background: #e1ab01;
  border-bottom: 1px solid #e1ab01;
  z-index: 2;
  color: black;
  font-weight:bold;
}

[type=radio]:checked ~ label ~ .content{
  z-index: 1;
}
.page.account{
  color:white;
  background-color:black;
}
.page.account .stats li{
  padding: 15px;
}
.captain-portal table{
  color:white;
  width: 100%;
}
.captain-portal table tr{
  border:1px solid white;
  padding: 3px;
}
.captain-portal table tr td:first-of-type{
  padding:15px;
}
.submit-form .btn-yellow{
  margin:0;
  background-color: #e1ab01;
  margin: 0 auto;
  padding: 10px;
  margin-bottom: 10px;
  text-align: center;
  color: #000;
  font-weight: 700;
}
.submit-form input{
  background-color: black;
  color: white;
  padding: 5px;
  border: 0;
  border-bottom: 1px solid white;
  margin-bottom: 10px;
  width: 100%;
}

.page.signup{
  background-color: black;
  color: white;
}
.page.signup .league-radio-container{
  margin-top: 15px;
}
.page.signup .league-radio{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 15px;
}
.page.signup .league-radio input{
  margin-left: 10px;
  width: fit-content;
}