/* Game Results */
.page.records {
  background-color: #ffffff;
  color: #000;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
}
.page.records h2{
  font-size: 30px;
    font-weight: 100;
    line-height: 36px;
    padding: 10px 0;
    background-color: #000;
    color: #ffffff;
}
.records table {
  width: 100%;
  color: #000;
  text-align: left;
  margin-bottom: 25px;
  border: 1px solid #000;
}
.records td{
  padding: 0 5px;
  margin-right: 5px;
  font-family: HeadingNow91;
  font-size: 12px;
  line-height: 26px;
  font-weight: 700;
}
.records td.teamname{
  font-family: HeadingNow91;
  text-decoration: none;
  color: #000;
  font-size: 12px;
  font-weight: 700;
}
.records th{
  font-family: HeadingNow91;
  font-size: 12px;
  line-height: 26px;
  font-weight: 700;
}
.records table th:last-of-type,
.records table td:last-of-type {
  text-align: right;
}

.record-container{
  padding: 0 20px;
}