li {
  width: fit-content;
  cursor: pointer;
}
.overview,
.league-rules,
.player-agreement {
  display: none;
  padding: 10px;
}
.show {
  display: block;
}
.hide {
  display: none;
}
