.page.standings {
  background-color: #000;
  color: #ffffff;
}
.standings table {
  width: 100%;
  text-align: left;
  color: #ffffff;
}

.standings td a{
  text-decoration: none;
  color: #ffffff;
}