.footer {
  background-color: #000;
  padding: 15px;
  width: 100%;
  height: fit-content;
  position: absolute;
}
.footer li {
  padding: 3px 0;
}
.footer li a {
  text-transform: uppercase;
  color: white;
  text-decoration: none;
}
.footer .icon-container{
  width: 100%;
  display: flex;
}
.footer .icon-container a{
margin-right: 15px;
}
.footer .icon-container a svg{
  height: 25px;
}