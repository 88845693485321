@font-face {
  font-family: "Coluna";
  src: local("Coluna"), url(../../Fonts/Coluna/Coluna.otf) format("opentype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
.navlinks--container {
  height: 100%;
  display: flex;
  align-items: center;
}
.navlinks--links-wrapper {
  margin: 0;
  padding: 0;
  display: flex;
  height: 100%;
  list-style: none;
}
.navlinks--sublink-wrapper {
  margin: 0;
  padding: 0;
  display: flex;
  height: 100%;
  list-style: none;
  display: none;
}
.navlinks--link {
  height: 100%;
  padding: 0 1.1em;
  color: #222;
  font-weight: 500;
  font-size: 24px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-top: 2px solid transparent;
  transition: all 220ms ease-in-out;
}
.navlinks--link:hover {
  border-top: 2px solid #0077c0;
}
.navlinks--links-wrapper .navlinks--link a {
  text-decoration: none;
  color: #ffffff;
  width: 100%;
  text-align: center;
}
.navlinks--sublink {
  height: 100%;
  padding: 0 1.1em;
  color: #222;
  font-weight: 500;
  font-size: 24px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-top: 2px solid transparent;
  transition: all 220ms ease-in-out;
}
.navlinks--sublink:hover {
  border-top: 2px solid #0077c0;
}

/* Navlinks Mobile */

.mobile {
  font-family: HeadingNow !important;
  letter-spacing: 5px;
}
.mobile .navlinks--links-wrapper {
  margin: 0;
  padding: 0;
  display: flex;
  height: 100%;
  list-style: none;
  background-color: #000;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  top: 59px;
  left: 0;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: visibility 0s, opacity 0.5s linear;
}
.mobile .open {
  visibility: visible;
  opacity: 1;
}
.mobile .navlinks--links-wrapper .navlinks--link-item {
  width: 100%;
  padding: 0 1.1em;
  color: #222;
  font-weight: 500;
  font-size: 26px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}
.mobile .navlinks--links-wrapper .navlinks--link-item a {
  width: 100%;
  text-align: center;
  color: #ffffff;
  text-decoration: none;
  font-family: HeadingNow94;
  letter-spacing: 5px;
  font-size: 22px;
  text-transform: uppercase;
}
.mobile .navlinks--links-wrapper .navlinks--link-item span {
  text-decoration: none;
  color: #aaa;
  width: 100%;
  text-align: center;
}

/* Navbar */
.navbar {
  width: 100%;
  height: 60px;
  background-color: #000;
  display: flex;
  align-items: center;
  position:fixed;
  z-index:999;
  border-bottom: 1px solid #ffffff;
}
.navbar .navbar--left-section {
  display: flex;
}
.navbar .navbar--left-section a{
  cursor: pointer;
  color: #ffffff;
  text-decoration: none;
  padding: 15px;
}
.navbar .navbar--left-section a svg{
  width: 23px;
}
.navbar .navbar--middle-section {
  display: flex;
  flex: 2;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.navbar .navbar--middle-section span{
  color: #ffffff;
  font-size: 24px;
}
.navbar .navbar--middle-section a {
  text-decoration: none;
  color: white;
}
.navbar .navbar--right-section {
  display: flex;
}

/* Logo */
.navbar {
  font-family: Coluna;
  letter-spacing: 5px;
  font-size: 36px;
}
.navbar .logo-wrapper {
  display: flex;
  align-items: center;
}
.navbar .logo-img {
  width: 30px;
  height: 30px;
}
.navbar .logo-text {
  font-size: 26px;
  margin: 0;
  margin-left: 4px;
  color: #ffffff;
  font-weight: 500;
  text-align:center;
}
.navbar .logo-text a {
  color: #ffffff;
  text-decoration: none;
  font-family: HeadingNow94;
  letter-spacing: 5px;
  font-size: 15px;
  text-transform: uppercase;
}
