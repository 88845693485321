.leagues .d1,
.leagues .d2 {
  margin-top: 25px;
  min-height: 800px;
}
.leagues .d2 .content{
  max-width: 550px;
}
.leagues .main-menu {
  width: 100%;
}
.leagues .main-menu.registration{
  background-color: #e1ab01;
}
.leagues .main-menu.registration .menu-item a{
  color: #000;
  font-weight: 700;
}
.leagues .main-menu .menu-item{
  background-color: #e1ab01;
}
.leagues .page {
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.leagues a{
  text-decoration: none;
}
.leagues.background1 .page{
  background: linear-gradient( rgba(0, 0, 0, .8), rgba(0, 0, 0, 1) ), url('../../images/Background/tower.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.leagues.background2 .page{
  background: linear-gradient( rgba(0, 0, 0, .8), rgba(0, 0, 0, 1) ), url('../../images/Background/graffiti2.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/* The switch - the box around the slider */

.switch {
  position: relative;
  display: inline-block;
  width: 65px;
  height: 34px;
}
/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  bottom: 4px;
  left: 4;
  background-color: #000;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.leagues input:checked + .slider {
  background-color: #e1ab01;
}
.leagues input:checked + .slider:before {
  background-color: #000;
}

.leagues input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

.leagues input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.leagues input:checked ~ .d2 {
  display: none;
}

/* Rounded sliders */

.slider.make-round {
  border-radius: 34px;
}

.slider.make-round:before {
  border-radius: 50%;
}

.leagues .toggle--container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 25px;
}
.leagues .toggle--container div {
  margin: 5px;
}

/* POTW */
.leagues .potw--container {
  display: flex;
  height: fit-content;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.leagues .potw--header {
  margin-bottom: 10px;
}
.leagues .potw--header h3 {
  margin-bottom: 0;
  font-size: 22px;
}
.leagues .potw--text {
  margin-top: 10px;
  height: 15px;
}
.leagues .potw--image-container {
  display: flex;
  flex-direction: column;
}
.leagues .potw--image-container div {
  display: flex;
  align-items: flex-start;
}
.leagues .potw--image-container div>img {
  height: 250px;  
  width: 250px;
  object-fit: cover;
  object-position: top;
  border: 1px solid #e1ab01;
  box-shadow: 0px 0 15px 0px #e1ab01;
}

/* Week Results */

.leagues .dayofweek-list {
  display: flex;
}
.leagues .dayofweek {
  display: flex;
  flex-direction: column;
}

.leagues .week-list {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  font-size: 25px;
}
.leagues .week-list div{
  font-family: HeadingNow;
  letter-spacing: 2px;
  font-size: 26px;
}
.leagues .eventbox{
  width: 100%;
  background-color: #000;
  display: flex;
  max-height: 100px;
}
.leagues .eventbox-container {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
}
.leagues .eventbox-container .btn-yellow {
  display: none;
}
.leagues .btn-yellow{
  width: 300px;
}
/* .leagues .eventbox-container span {
  display: none;
} */
.leagues .eventbox--details-teams .homeaway{
  display: none;
}
.leagues .eventbox-container span.spacer {
  display: block;
}
.leagues .eventbox-list {
  width:80%;
}
.leagues .eventbox-list {
  width:80%;
}
/* [5] */
.leagues .eventbox-list::-webkit-scrollbar {
  display: none;
}
.leagues .eventbox--details.schedule{
  justify-content: center;
  align-items: center;
}
.leagues .eventbox--details.schedule .eventbox--details-teams{
  flex-direction: column;
  width: 100%;
}
.leagues .eventbox--details-datetime{
  display: none;
}
.leagues .eventbox--details-datetime.leaguepage{
  display: block;
}



/* Icon Container */
.leagues .icon-menu {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.leagues .icon-menu li.menu-item {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  height: 100px;
  width: 100px;
  margin-bottom: 5px;
}
.leagues .icon-menu li.menu-item a {
  text-decoration: none;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.leagues .icon-menu li.menu-item svg {
  border: 1px solid #e1ab01;
  width: 50px;
  height: 50px;
  padding: 5px;
  margin-bottom: 5px;
  box-shadow: 0 0 15px 0px #e1ab01;
}

/* LEAGUE INFORMATION */
.league-info--box {
  border: 1px solid #e1ab01;
  background-color: #000;
  display: flex;
  align-items: center;
  min-height: 450px;
  position: relative;
  padding-left: 75px;
  padding-right: 50px;
  margin-bottom: 25px;
}
.league-info--title {
  background-color: #e1ab01;
  width: 450px;
  height:50px;
  padding: 5px;
  transform: rotate(-90deg);
  position: absolute;
  left: -200px;
  font-size: 25px;
  color: #000;
  font-weight: 700;
}
h2.league-info--title{
  margin-bottom: 0;
}
.league-info--content {
  text-align: left;
  width: 100%;
}

/* LEAGUE RULES */
.league-rules--container{
border: 1px solid #e1ab01;
box-shadow: 0px 0 15px 0px #e1ab01;
background-color: #000;
padding: 25px;
margin-bottom: 25px;
min-height:275px;
text-align: center;
display: flex;
align-items: center;
justify-content: center;
}
.league-rules--title{
display: block;
color:#fff;
}
.league-rules--content{
display: none;
}
.league-rules--input{
  display: none;
}
.league-rules--input:checked + label .league-rules--content{
  display: block;
  font-weight: 700;
}
.league-rules--input:checked + label .league-rules--title{
  display: none;
}
.league-rules--input:checked + label .league-rules--container{
  background-color: #e1ab01;
  color: #000;
}
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1;}
}