.page.registration {
  background-color: #ffffff;
  color: #000;
  padding-top: 60px;
}

.registration form {
  display: flex;
  flex-direction: column;
}
.registration .form-element {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  width: 100%;
}

.registration .plus,
.registration .minus {
  width: 25px;
}

.registration .form-element .form-element--name {
  display: flex;
  flex-direction: row;
}

.registration table {
  width: 100%;
  text-align: left;
  color: #ffffff;
}


.form-style-1 {
  /* margin: 10px auto; */
  max-width: 400px;
  padding: 20px 12px 10px 0px;
  font: 13px "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  align-self: center;
}
.form-style-1 li {
  padding: 0;
  display: block;
  list-style: none;
  margin: 10px 0 0 0;
}
.form-style-1 label {
  margin: 0 0 3px 0;
  padding: 0px;
  display: block;
  font-weight: bold;
}
.form-style-1 input[type="text"],
.form-style-1 input[type="date"],
.form-style-1 input[type="datetime"],
.form-style-1 input[type="number"],
.form-style-1 input[type="search"],
.form-style-1 input[type="time"],
.form-style-1 input[type="url"],
.form-style-1 input[type="email"],
textarea,
select {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  border: 1px solid #bebebe;
  padding: 7px;
  margin: 0px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  outline: none;
}
.form-style-1 input[type="text"]:focus,
.form-style-1 input[type="date"]:focus,
.form-style-1 input[type="datetime"]:focus,
.form-style-1 input[type="number"]:focus,
.form-style-1 input[type="search"]:focus,
.form-style-1 input[type="time"]:focus,
.form-style-1 input[type="url"]:focus,
.form-style-1 input[type="email"]:focus,
.form-style-1 textarea:focus,
.form-style-1 select:focus {
  -moz-box-shadow: 0 0 8px #0077c0;
  -webkit-box-shadow: 0 0 8px #0077c0;
  box-shadow: 0 0 8px #0077c0;
  border: 1px solid #0077c0;
}
.form-style-1 .field-divided {
  width: 49%;
}

.form-style-1 .field-long {
  width: 100%;
}
.form-style-1 .field-select {
  width: 100%;
}
.form-style-1 .field-textarea {
  height: 100px;
}
.form-style-1 input[type="submit"],
.form-style-1 input[type="button"] {
  background: #000;
  padding: 8px 15px 8px 15px;
  border: none;
  color: #ffffff;
  font-family: HeadingNow94;
  font-size: 22px;
}
/* .form-style-1 input[type="submit"]:hover,
.form-style-1 input[type="button"]:hover {
  background: #0077c0;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
} */
.form-style-1 .required {
  color: red;
}
