/* Game Results */
.page.stats {
  background-color: #ffffff;
  color: #000;
  /* background: linear-gradient( rgba(0, 0, 0, .8), rgba(0, 0, 0, 1) ), url('../../images/Background/balloon.jpeg'); */
  padding-left: 0;
  padding-right: 0;
}
.page.stats h2{
  font-size: 30px;
    font-weight: 100;
    line-height: 36px;
    padding: 10px 0;
    background-color: #000;
    color: #ffffff;
}
.stats table {
  width: 100%;
  color: #000;
  text-align: left;
  margin-bottom: 10px;
  border: 1px solid #000;
}
.stats td{
  padding: 0 5px;
  margin-right: 5px;
  font-family: HeadingNow91;
  font-size: 12px;
  line-height: 26px;
  font-weight: 700;
}
.stats td.teamname{
  font-family: HeadingNow91;
  text-decoration: none;
  color: #000;
  font-size: 12px;
  font-weight: 700;
}
.stats th{
  font-family: HeadingNow91;
  font-size: 12px;
  line-height: 26px;
  font-weight: 700;
}

.stats table th:last-of-type,
.stats table td:last-of-type {
  text-align: right;
}

.page.stats a {
  display: block;
  margin: 0 5px 15px 5px;
  text-decoration: none;
  color: #000;
}

.record-container{
  padding: 0 5px;
}