/*
 *  Flex Layout Specifics
*/
.playoffBracket.page {
    background-color: #000;
    color: #ffffff;
    position: relative;

}
#container{
  
}
#bracketContainer{
  overflow-x: scroll;
}
main{
  display:flex;
  flex-direction:row;
}
.round{
  display:flex;
  flex-direction:column;
  justify-content:center;
  width:200px;
  list-style:none;
  padding:0;
}
  .round .spacer{ flex-grow:1; }
  .round .spacer:first-child,
  .round .spacer:last-child{ flex-grow:.5; }

  .round .game-spacer{
    flex-grow:1;
  }

/*
 *  General Styles
*/
/* body{
  font-family:sans-serif;
  font-size:small;
  padding:10px;
  line-height:1.4em;
} */

li.game{
  width: 100%;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

  li.game.WIN{
    font-weight:bold;
    color: black;
    background-color: #e1ab01;
    box-shadow: 0px 0 15px 0px #e1ab01;
    padding: 2px;
  }
  li.game.LOSS{
    color: #ffffff;
  }
  li.game.winner{
    font-weight:bold;
    color: black;
    background-color: #e1ab01;
    box-shadow: 0px 0 15px 0px #e1ab01;
    padding: 2px;
  }
  li.game span{
    float:right;
    margin-right:5px;
  }

  li.game-top{ border-bottom:1px solid #aaa; }

  li.game-spacer{ 
    border-right:1px solid #aaa;
    min-height:40px;
  }

  li.game-bottom{ 
    border-top:1px solid #aaa;
  }
  .playoffBracket.page .slider--logo-container{
    width: 100%;
    height: 100%;
    position: relative;
  }
  .playoffBracket.page .slider--logo{
    top: 25%;
    position: absolute;
    opacity: .1;
    left: 0;
  }