.page.results-by-week {
    background-color: #000;
    color: #ffffff;
  }
  
  .results-by-week .eventbox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 25px;
    border: 1px solid #e1ab01;
    margin: 15px 0;
  }
  .results-by-week .eventbox>a{
    justify-content: space-between;
    flex-direction: column;
  }
  .results-by-week .eventbox--details.schedule {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .results-by-week .eventbox--details.schedule .eventbox--details-teams {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    margin-left: 0;
  }
  .results-by-week .eventbox--details.schedule .eventbox--details-teams .spacer {
    margin: 0 10px;
    align-self: center;
  }
  .results-by-week .eventbox--details.results .eventbox--details-teams .spacer {
    margin: 0 10px;
  }
  .results-by-week .eventbox--details.results {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
  }
  .results-by-week .eventbox--details-teams {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .results-by-week .btn-yellow{
    width: 100%;
    height: 25px;
    line-height: 25px;
    margin: 0;
    margin-top: 25px;
    padding: 0;
  }
  
.results-by-week .eventbox--details.results{
  width: 100%;
}
.results-by-week .eventbox--details-teams {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 0;
  width: 100%;
}
.results-by-week .eventbox--details-team{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50px;
}
.results-by-week .eventbox--details-team>span{
  margin-bottom: 15px;
}
  .results-by-week .dayofweek-list {
    display: flex;
  }
  .results-by-week .dayofweek {
    display: flex;
    flex-direction: column;
  }
  
  .results-by-week .week-list {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    font-size: 25px;
  }
  .results-by-week h4 {
    font-family: HeadingNow;
    letter-spacing: 5px;
    font-size: 18px;
  }
  
  /* Game Results */
  .page.game-results {
    background-color: #000;
    color: #ffffff;
  }
  .game-results table {
    width: 100%;
    color: #ffffff;
    text-align: left;
  }
  
  .game-results td {
  }
  
  /* .WIN {
    color: #0077c0;
  }
  .LOSS {
    color: #e1ab01;
  } */

  .results-by-week .details-teams-column{
    width: 33%;
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .results-by-week .eventbox--details-datetime>div{
    width: auto;
  }
  /* .results-by-week .homeaway{
    margin-bottom: 15px;
  }
  .results-by-week .eventbox--details-datetime{
    margin-bottom: 15px;
  } */
  .results-by-week .eventbox--details-datetime.leaguepage{
    display: none;
  }