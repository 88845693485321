.page.contact{
    background-color: #000;
    color: #ffffff;
}

.contact a{
    color: white;
    text-decoration: none;
}
.contact .contact-text{
    display: flex;
    align-items: center;
}
.contact h3{
    margin-bottom: 0;
}