@import url(https://db.onlinewebfonts.com/c/8af4f981069f65fe3806caf753e04464?family=Karumbi+Regular);
@import url('https://use.typekit.net/qda7htu.css');

.page.results {
  background-color: #000;
  color: #ffffff;
}
.page.results .main-menu{
  width: 100%;
}
.page.results .main-menu a{
  font-family: HeadingNow;
  font-size: 26px;
  text-align: center;
}
.page.results.menu{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.eventbox {
  /* display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; */
  width: 100%;
  padding: 25px;
  border: 1px solid #e1ab01;
  margin: 15px 0;
  text-decoration: none;
  color: #ffffff;
  padding: 25px;
  text-align:center;
}
.eventbox>a{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-decoration: none;
}
.eventbox--details.schedule {
  display: flex;
  flex-direction: row;
  justify-content: left;
  width: 100%;
}
.eventbox--details.schedule .eventbox--details-teams {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 15px;
}
.eventbox--details.schedule .eventbox--details-teams .spacer {
  margin: 0 10px;
}
.eventbox--details.results {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.minimal.eventbox {
  width: 100%;
  border: none;
  border-bottom: 1px solid #000;
  padding: 5px;
  margin: 0;
  text-decoration: none;
  color: #000;
  text-align:center;
  background-color: #ffffff;
}
.minimal.eventbox>a{
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  text-decoration: none;
}
.minimal.eventbox--details.schedule {
  display: flex;
  flex-direction: row;
  justify-content: left;
  width: 100%;
}
.minimal.eventbox--details.schedule .minimal.eventbox--details-teams {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 15px;
}
.minimal.eventbox--details.schedule .minimal.eventbox--details-teams .spacer {
  margin: 0 10px;
}
.minimal .eventbox--details.results {
  display: flex;
  align-items: center;
  width: 100%;
}
.minimal.eventbox .results .eventbox--details-teams {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width:100%;
}
.minimal.eventbox .details-teams-column{
  display: flex;
  flex-direction: column;
  width: 20%;
  overflow: hidden;
}
.minimal.eventbox .details-teams-column.team{
  width: 30%;
}
.minimal.eventbox .details-teams-column.score{
  width: 10%;
}
.minimal.eventbox .details-teams-column .details-teams-results{
  font-family: HeadingNow91;
  font-size: 12px;
}
.minimal.eventbox .details-teams-column div{
  font-size: 12px;
  line-height:25px;
  font-family: HeadingNow91;
  font-weight: 700;
}
.minimal.eventbox .results .eventbox--details-datetime{
  display: flex;
  flex-direction: column;
}
.minimal.eventbox .results .eventbox--details-datetime div{
  font-family: HeadingNow91;
  font-style: normal;
  font-size: 12px;
}
.results .eventbox--details-teams {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.results .dayofweek-list {
  display: flex;
}
.results .dayofweek {
  display: flex;
  flex-direction: column;
}

.results .week-list {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  font-size: 25px;
}
.results h4 {
  font-family: HeadingNow;
  letter-spacing: 5px;
  font-size: 18px;
}

/* Game Results */
.page.game-results {
  background-color: #ffffff;
  color: #000;
  
  overflow: scroll;
  -webkit-overflow-scrolling: touch; 
}
.game-results table {
  width: 100%;
  color: #000;
  text-align: left;
  display: block;
  /* max-width: -moz-fit-content;
  max-width: fit-content; */
  margin: 0 auto;
  overflow-x: auto;
  white-space: nowrap;
  position: relative;
  overflow: scroll;
  -webkit-overflow-scrolling: touch; 
}


.game-results td{
  padding: 0 5px;
  margin-right: 5px;
  font-family: HeadingNow91;
  font-size: 12px;
  line-height: 26px;
  font-weight: 700;
}
.game-results td.teamname{
  font-family: HeadingNow91;
  text-decoration: none;
  color: #000;
  font-size: 10px;
  font-weight: 700;
}
.game-results th{
  font-family: HeadingNow91;
  font-size: 12px;
  line-height: 26px;
  font-weight: 700;
}

/* .WIN {
  color: #0077c0;
}
.LOSS {
  color: #e1ab01;
} */