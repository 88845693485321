.page.gallery{
    background-color: black;
    color: white;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.galleryimg{
    width: 100%;
    height: 100%;
    margin-bottom: 5px;
}
.gallery .main-menu{
    width: 100%;
}