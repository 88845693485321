@import "~react-image-gallery/styles/css/image-gallery.css";
@import url(https://db.onlinewebfonts.com/c/8af4f981069f65fe3806caf753e04464?family=Karumbi+Regular);

.home {
}
.home .page {
  height: auto;
  padding: 0;
  padding-bottom: 25px;
  background-color: #ffffff;
  /* background-image: url("../../images/The Mecca_d00a_00a-01.svg");
  background-repeat: repeat;
  background-size: auto;
  background-position: unset; */
}
.home .main-menu {
  width: 50%;
  position: absolute;
  top: 25%;
  box-shadow: 0 0 10px 0px white;
}
.home .main-menu .menu-item {
  padding: 15px;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
}
.home .main-menu a {
  color: white;
  text-decoration: none;
}
.slider-container-container{
  padding-top:60px;
  position: relative;
}
.slider-container-container div{
z-index: 1;
width: 100%;
}
.slider-container-container div>a{
  position: absolute;
  top: 33%;
  left: 50%;
  transform: translate(-50%, 50%);
  text-align: center;
  width: 50%;
  text-decoration: none;
  color: #000;
  background-color: #ffffff;
  padding: 15px;
  font-family: HeadingNow91;
  font-weight: 700;
}
.slider-container-container iframe{
z-index: 0;
}
.slider-container-container iframe{
  width: 100%;
  height: calc((100vw*9)/16);
    display: block;
    margin: 0 auto;
}
.slider--container {
  width: 100%;
  position: relative;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.alice-carousel {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  top: 0;
}
.alice-carousel ul li img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.home .page h2{
  font-size: 30px;
  font-weight: 100;
  line-height: 36px;
  padding: 10px 0;
  background-color: #000;
  color: #ffffff;
}
/* Division Panels */
.big-division-info--container{
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-top: 25%;
  padding: 15px;
  color: #ffffff;
  border: 1px solid #e1ab01;
  box-shadow: 0 0 15px 0 #e1ab01;
  width: 85%
}
.big-division-info--header{
  letter-spacing: 1px;
}
.big-division-info--fees{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.big-division-info--fees-fees{
  display: flex;
  flex-direction: column;
  align-items: start;
}
.big-division-info--fees-fee{
  font-size: 75px;
}
.big-division-info--fees-disclaimer{
  font-size: 13px;
}
.big-division-info--register a{
  text-decoration: none;
}
.big-division-info--container.free-agent .big-division-info--register a div{
  font-size: 13px;
}
.big-division-info--format{

}
.big-division-info--format h2{
  margin-bottom: 0;
}
.big-division-info--format-info{
  color: #e1ab01;
  text-transform: uppercase;
}
.big-division-info--format-info div{
  margin-bottom: 10px;
}
.big-division-info--container.free-agent .big-division-info--fees-fee{
  font-size: 100px;
}
@media screen and (min-width: 425px) {
  .big-division-info--container.d1 .big-division-info--header{
    font-size: 39px;
  }
  .big-division-info--container.d2 .big-division-info--header{
    font-size: 57px;
  }
  .big-division-info--container.free-agent .big-division-info--header{
    font-size: 43px;
  }
}
@media screen and (max-width: 424px) {
  .big-division-info--container.d1 .big-division-info--header{
    font-size: 32px;
  }
  .big-division-info--container.d2 .big-division-info--header{
    font-size: 45px;
  }
  .big-division-info--container.free-agent .big-division-info--header{
    font-size: 35px;
  }
}
h2{
  text-align: center;
}
.week-list, .league-list{
  display: flex;
  justify-content: space-around;
  margin-bottom:15px;
}
.week-list span, .league-list span{
  font-size: 25px;
}
.week-list div, .league-list div{
  line-height: 25px;
  font-family: HeadingNow91;
  font-size: 22px;
  font-weight: 700;

}

.home-standings{
  background-color: #ffffff;
  color: #000;
  padding: 0 25px;
  display: inline-block;
  zoom: 1;
  position: relative;
  clip: auto;
  overflow: hidden;
  width: 100%;
}
.home-standings a{
  font-family: Karumbi;
  text-decoration: none;
  color: #000;
  font-size: 22px;
  font-weight: 700;
}
.home-standings .standing-row{
  position: relative;
  text-align: right;
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
}

.home-standings .standing-row>div>div{
  margin-right: 5px;
  font-family: HeadingNow91;
  font-size: 12px;
  line-height: 26px;
  font-weight: 700;
}
.home-standings .standing{
  font-family: HeadingNow91;
    font-size: 12px;
    font-weight: 700;
    height: 25px;
    line-height: 25px;
    padding-left: 10px;
    position: relative;
    background: #ffffff;
    align-self: flex-end;
    z-index: 1;
}
.home-standings .filler{
  position: absolute;
    left: 0;
    right: 0;
    border-bottom: 1px dashed #000;
    height: 50%;
}
.home-standings .team{
  display: flex;
    float: left;
    margin-right: 20px;
    padding-right: 4px;
    position: relative;
    background: #ffffff;
    z-index: 1;
    height: 25px;
}